import { GET_DOWNLINES_INFO, DOWNLOAD_DOWNLINES_INFO, GET_DOWNLINES_BVS_INFO } from '@/config/endpoint';
import { apiFilters } from '@/config/axios';
import Req from './AxiosRequest';

class DownlinesInfo {
	constructor() {
		this.data = new Req(apiFilters);
		this.customDataObject = {};
		this.errors = this.data.errors;
		this.loading = this.data.loading;
		this.pagination = this.data.pagination;
	}

	clear() {
		this.data.clear();
	}

	getDownlinesReport(userId, options) {
		const { method, endpoint } = GET_DOWNLINES_INFO;
		return this.data[method](endpoint(userId), options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getBvs(userId, options) {
		const { method, endpoint } = GET_DOWNLINES_BVS_INFO;
		return this.data[method](endpoint(userId), options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	download(userId) {
		const { method, endpoint } = DOWNLOAD_DOWNLINES_INFO;
		return this.data.getBlobData({ method, url: endpoint(userId) }).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default DownlinesInfo;
