import { admin } from '@/settings/Roles';

export default {
	data() {
		return {
			distributor: '',
			admin,
		};
	},
	watch: {
		username(user) {
			this.$parent.titleParam = { user };
		},
	},
	mounted() {
		this.$parent.titleParam = { user: this.username };

		const { distributorId } = this.$route.params;
		if (typeof distributorId !== 'undefined') {
			this.distributor = distributorId;
		}
	},
	methods: {
		changeDistributor() {
			const distributorId = parseInt(this.distributor, 10);
			if (!Number.isNaN(distributorId)) {
				const { name, query } = this.$route;
				const params = { ...this.$route, distributorId };
				this.$router.replace({ name, params, query }).catch(() => {});
			}
		},
		clearDistributor() {
			const { name } = this.$route;
			this.$router.replace({ name }).catch(() => {});
		},
	},
};
